import { createApp } from 'vue'
import '@/style.css'
import router from './router'
import store from './store/index'
import { MotionPlugin } from '@vueuse/motion'

// Import Vuetify from your plugin
import vuetify from './plugins/vuetify'

import App from './App.vue'

createApp(App).use(MotionPlugin).use(router).use(store).use(vuetify).mount('#app')
