<template>
    <section class="h-auto page-bg">
        <div class="flex flex-col justify-center items-center xsm:pt-24 lg:pt-44 md:pt-44 max-w-[1121px] w-full m-auto">
            <div class="container bg-[#029B38]/[0.3] rounded-lg md:pt-12 sm:pt-8 xsm:pt-5 mb-[60px] xsm:px-[20px] sm:px-[28px]">
                <div class="container wrapper z-1 font-rubik">
                    <div class="text-white text-center text-[#08E399] lg:text-4xl md:text-3xl drop-shadow-md sm:text-xl xsm:text-xl font-extrabold">OUR REWARD SYSTEM</div>
                </div>
                <div class="container font-rubik pt-20 md:pt-[70px] pb-[60px]">
                    <h2 class="text-white uppercase font-bold text-xl py-2">Unlock exclusive rewards as part of Seham's community </h2>
                    <h3 class="text-white uppercase font-bold text-lg pl-4 pt-2">1. WHAT CAN ACTIVE VIEWER GET?</h3>
                    <p class="text-white font-normal text-md pl-8 md:text-normal pb-1">If you are an active viewer on Seham's stream, you will have access to daily chat raffles and slot requests. 
                                                                                        You can either take 10% of the profit from your slot request or receive a stake tip depending on the profit. 
                                                                                        Additionally, the more you watch the stream and participate, the more points you can accumulate and redeem 
                                                                                        for bonuses and cash on our website.</p>
                    <h3 class="text-white uppercase font-bold text-lg pl-4 pt-2">2. WHAT CAN A SUBSCRIBER GET?</h3>
                    <p class="text-white font-normal text-md pl-8 md:text-normal pb-1">Subscribing to Seham's channel or gifting subs is highly appreciated. Subscribers will receive priority 
                                                                                        for slot requests and have their names added to our premium giveaways wheel. Additionally, for every sub you give, 
                                                                                        you will earn extra points to redeem for bonuses and cash on our website.</p>
                    <h3 class="text-white uppercase font-bold text-lg pl-4 pt-2">3. WHAT CAN A PLAYER UNDER SEHAM’S CODE GET?</h3>
                    <p class="text-white font-normal text-md pl-8 md:text-normal pb-1">By signing up with the code Seham on Stake.com/Stake.us, you can join a $24,000 reward, a $5,000 monthly 
                                                                                        leaderboard, and a $20,000 VIP level-up bonus, exclusively under the code Seham. Furthermore, you will receive a 
                                                                                        200% deposit bonus on your first deposit (with a minimum deposit of $50 and a maximum of $500). For example, 
                                                                                        if you deposit $500, you will receive an instant $1,000. You can also claim free cash on your first deposit, 
                                                                                        with a minimum deposit of $50 and a maximum of $1,000. For instance, if you deposit $100, you will receive $10 for free. 
                                                                                        Additionally, reaching different VIP levels will unlock rewards.</p>
                    <p class="text-white font-normal text-md pl-8 md:text-normal py-1">As an example, if you reach Plat 1 you get 150$ from Seham + Stake rewards</p>
                    <p class="text-white font-normal text-md pl-8 md:text-normal py-1 ">Your name will also be added to premium giveaways, and the more you wager or deposit, the more points you 
                                                                                        will earn to redeem for bonuses and cash on our website.</p>

                </div>
            </div>
        </div>
    </section>
    
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.page-bg {
    background-image: url('../assets/page-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
}
.font-rubik {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
}
</style>
