<template>
    <section class="h-auto page-bg">
        <div class="flex flex-col justify-center items-center xsm:pt-24 lg:pt-44 md:pt-44 max-w-[1121px] w-full m-auto">
            <div class="container bg-[#029B38]/[0.3] rounded-lg md:pt-12 sm:pt-8 xsm:pt-5 mb-[60px] xsm:px-[20px] sm:px-[28px]">
                <div class="container wrapper z-1 font-rubik">
                    <div class="text-white text-center text-[#08E399] lg:text-4xl md:text-3xl drop-shadow-md sm:text-xl xsm:text-xl font-extrabold">HOW TO CREATE ACCOUNT ON STAKE</div>
                    <div class="text-white text-center text-white uppercase lg:text-[22px] md:text-2xl sm:text-lg xsm:text-lg drop-shadow-md font-semibold ">A STEP BY STEP GUIDE ON HOW TO CREATE AN ACCOUNT ON STAKE</div>
                </div>
                <div class="container font-rubik pt-20 md:pt-[70px] border-b-[1px] border-[#fff]/50 pb-[60px]">
                    <h2 class="text-white uppercase font-bold text-xl">Signup with code: <span class="text-[#08E399]">SEHAM</span> </h2>
                    <h3 class="text-white uppercase font-bold text-lg pl-4 pt-1">1. Visit Stake.com</h3>
                    <p class="text-white font-normal text-md pl-8 md:text-normal pb-1">Go to the official Stake Casino website and click on the <span class="text-white text-md font-bold">"Register"</span> button located at the top right corner.</p>
                    <div class="reg-us flex md:flex-row sm:flex-row lg:flex-row xl:flex-row 2xl:flex-row xsm:flex-col">
                        <div class="">
                            <p class="text-[#08E399] drop-shadow sm:font-normal 2xl:font-semibold xl:font-semibold lg:font-semibold text-md pl-8 md:text-normal pb-1 uppercase">If you are in the United States</p>
                            <a @click="openNewTab()" class="ml-8 rounded relative inline-flex group items-center justify-center px-3.5 py-2 m-1 cursor-pointer border-b-4 border-l-2 active:border-[#1475FF] active:shadow-none shadow-lg bg-gradient-to-r from-[#1475FF] to-indigo-500 border-[#1475FF] text-white">
                                <span class="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-32 group-hover:h-32 opacity-10"></span>
                                <span class="relative text-white">Register Here</span>
                            </a>
                        </div>
                        <div class="">
                            <p class="text-[#08E399] drop-shadow sm:font-normal 2xl:font-semibold xl:font-semibold lg:font-semibold text-md pl-8 md:text-normal pb-1 uppercase">If you are outside the United States</p>
                            <a @click="openNewTabUs()" class="ml-8 rounded relative inline-flex group items-center justify-center px-3.5 py-2 m-1 cursor-pointer border-b-4 border-l-2 active:border-[#029B38] active:shadow-none shadow-lg bg-gradient-to-tr from-[#029B38] to-[#08573D] border-[#029B38] text-white">
                                <span class="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-32 group-hover:h-32 opacity-10"></span>
                                <span class="relative text-white">Register Here</span>
                            </a>
                        </div>
                    </div>
                    <h3 class="text-white uppercase font-bold text-lg pl-4 pt-1">2. FILL IN THE REQUESTED INFORMATION</h3>
                    <p class="text-white font-normal text-md pl-8 md:text-normal pb-1">Enter the requested personal information - email, username, mobile number, password, and date of birth. If you are in the USA select your STATE</p>
                    <h3 class="text-white uppercase font-bold text-lg pl-4 pt-1">3. ENTER STAKE CODE <span class="text-[#08E399]">"SEHAM"</span></h3>
                    <p class="text-white font-normal text-md pl-8 md:text-normal pb-1">Tick the checkbox with the label CODE at the bottom part of the pop up window (modal). Enter the code SEHAM. Once done, click continue.</p>
                    <p class="text-white font-normal text-md pl-8 md:text-normal py-3">Read the following terms and conditions. If you agree, tick the checkbox and click "Play Now" button. Enjoy and please don't forget to gamble responsibly</p>
                    <h3 class="text-white uppercase font-bold text-lg pl-4 pt-1">4. CONNECT</h3>
                    <p class="text-white font-normal text-md pl-8 md:text-normal pb-1">Connect your Stake account seham.tv to be eligible for giveaways, raffles and other website features and community activities</p>

                </div>
                <div class="lg:flex md:flex-row">
                    <div class="container font-rubik lg:pt-20 md:pt-[70px] pb-[60px]">
                        <h2 class="text-white uppercase font-bold text-xl"> UNLOCK BENEFITS FOR SIGNING UP WITH CODE <span class="text-[#08E399]">SEHAM</span> </h2>
                        <h3 class="text-white uppercase font-normal text-lg pl-4 pt-1 mt-2">
                            <span class="inline-block mb-[-4px]"> 
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1771_213)">
                                            <path d="M11.5 23C17.8514 23 23 17.8514 23 11.5C23 5.14855 17.8514 0 11.5 0C5.14855 0 0 5.14855 0 11.5C0 17.8514 5.14855 23 11.5 23Z" fill="#08E399"/>
                                            <path d="M6.2959 12.2436L9.26981 15.2175L16.7046 7.78271" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    <defs>
                                        <clipPath id="clip0_1771_213">
                                            <rect width="23" height="23" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                        </span>
                        Fast access to giveaways and raffles
                        </h3>
                        <h3 class="text-white uppercase font-normal text-lg pl-4 pt-1 mt-2">
                            <span class="inline-block mb-[-4px]"> 
                                <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1771_213)">
                                            <path d="M11.5 23C17.8514 23 23 17.8514 23 11.5C23 5.14855 17.8514 0 11.5 0C5.14855 0 0 5.14855 0 11.5C0 17.8514 5.14855 23 11.5 23Z" fill="#08E399"/>
                                            <path d="M6.2959 12.2436L9.26981 15.2175L16.7046 7.78271" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                    <defs>
                                        <clipPath id="clip0_1771_213">
                                            <rect width="23" height="23" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                            Eligibility to join special events
                        </h3>
                    </div>
                        <div class="image-wrapper my-12 md:justify-self-center "><img src="../assets/promo-card-rewards-and-promotions.png" alt="seham promocard"></div>
                </div>
            </div>
        </div>
    </section>
    
</template>

<script>
    export default {
        methods: {
            openNewTab(){
                window.open('https://stake.com/registration', '_blank')
            },
            openNewTabUs(){
                window.open('https://stake.us/?tab=register&modal=auth', '_blank')
            }
        }
    }
</script>

<style scoped>
.page-bg {
    background-image: url('../assets/page-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
}

.font-rubik {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
}
</style>