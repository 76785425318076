import axios from "axios";

const token = localStorage.getItem("seham-access-token");

export const getUser = async () => {
	const response = await axios.get(process.env.VUE_APP_BASE_API + "/api/user", {
		headers: {
			"x-api-key": process.env.VUE_APP_X_API_KEY,
			Authorization: `Bearer ${token}`,
		},
	});
	return response.data;
};
