<template>

    <v-carousel
      height="490"
      hide-delimiter-background="true"
      hide-delimiters="true"
      class="md:mb-40 xsm:mb-10 max-w-[1121px]"
      
    >
      <v-carousel-item
        v-for="(slide, index) in slides" :key="index">
        <v-sheet
          :class="`sheet-color-none `"
          :color="index !== 0 ? color.primary : 'sheet-color-none'"
          height="100%"
        >
        <div v-if="index === 0" class="img-wrapper relative font-rubik flex flex-col flex-wrap">
            <div class="container absolute lg:top-[65px] xl:top-[75px] lg:left-[74px] xsm:left-[10%] xsm:top-[17%] flex flex-col flex-wrap xsm:w-[300px] max-w-[600px] sm:w-full sm:top-6 sm:left-6 justify-center">
                <h1 class="font-extrabold text-white sm:text-[40px] xsm:text-[30px] drop-shadow uppercase">First Deposit bonus!</h1>
                <ul class="list-disc sm:font-semibold xsm:font-normal xsm:pl-0 sm:pl-[16px] w-full">
                    <li class="uppercase text-white lg:text-[18px] xl:text-[18px] 2xl:text-[18px] md:text-normal sm:text-normal xsm:text-[14px] pb-2">200% For 1st Deposit Only</li>
                    <li class="uppercase text-white lg:text-[18px] xl:text-[18px] 2xl:text-[18px] md:text-normal sm:text-normal xsm:text-[14px] pb-2">Min Deposit of $50 and Max Deposit Bonus of $500 and wager x40</li>
                    <li class="uppercase text-white lg:text-[18px] xl:text-[18px] 2xl:text-[18px] md:text-normal sm:text-normal xsm:text-[14px] pb-2">Extra First Deposit bonus from Seham</li>
                    <li class="uppercase text-white lg:text-[18px] xl:text-[18px] 2xl:text-[18px] md:text-normal sm:text-normal xsm:text-[14px] pb-2">Claim 10% of your first deposit by opening ticket on discord</li>
                    <li class="uppercase text-white lg:text-[18px] xl:text-[18px] 2xl:text-[18px] md:text-normal sm:text-normal xsm:text-[14px] pb-2">Minimum Deposit $50 maximum $1000</li>
                    <li class="uppercase text-white lg:text-[18px] xl:text-[18px] 2xl:text-[18px] md:text-normal sm:text-normal xsm:text-[14px] pb-2">Claim it after wagering x20</li>
                </ul>
            </div>
            <img :src="image.slideOne" class="md:w-full h-[auto] xsm:translate-x-[-375px] md:translate-x-0 lg:translate-x-0 xsm:z-[-1] xsm:max-w-max" alt="">
        </div>
        <div v-else-if="index === 1" class="img-wrapper relative font-rubik flex flex-row flex-wrap items-center">
            <div class="img-container flex-1">
                <img :src="!isMobile() == true ? image.slideTwo : ''" class="md:h-[490px] md:w-auto" alt="">
            </div>
            <div class="slide-contents sm:flex-1 xsm:flex-0 xsm:px-1 xsm:py-3 xsm:px-5">
                <ul class="flex flex-col items-center">
                    <li class="flex sm:flex-row xsm:flex-col items-center">
                      <img src="../assets/play-button-icon.png" class="w-[16px] h-[16px]" alt="">
                      <a href="https://kick.com/sehamx" class="text-slate-300 pl-1">kick.com/sehamx</a>
                    </li>
                    <li class=""><h2 class="uppercase font-extrabold drop-shadow text-[#08E59A] text-[45px] xsm:text-[38px]">BE A MEMBER TODAY!</h2></li>
                    <li><h2 class="uppercase font-medium drop-shadow text-slate-200 text-[20px] xsm:text-[18px]">How to create an account on stake</h2></li>
                    <li class="py-5">
                      <router-link to="rewards-and-promotions/how-to-create-account-on-stake" class="inline-flex items-center w-full px-5 py-3 mb-3 mr-1 text-base font-semibold 
                                          text-white no-underline align-middle bg-[#000]/60 border border-transparent border-solid rounded-md 
                                          cursor-pointer select-none sm:mb-0 sm:w-auto hover:bg-[#08E59A] hover:[#08E59A] hover:text-white 
                                          focus-within:bg-[#08E59A] focus-within:border-[#08E59A]">Quick Guide
                        <svg class="w-4 h-4 ml-2" 
                              fill="#fff" 
                              stroke="#fff" 
                              viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path stroke-linecap="round" 
                              stroke-linejoin="round" stroke-width="2" 
                              d="M14 5l7 7m0 0l-7 7m7-7H3">
                          </path>
                        </svg>
                        </router-link>
                    </li>
                    <li class="flex flex-row flex-wrap">
                      <a href="https://x.com/xSehamm" class="text-slate-300 pl-1 px-2"><img src="../assets/twitter.png" class="w-[18px] h-[18px]" alt=""></a>
                      <a href="https://www.instagram.com/xSehamm" class="text-slate-300 pl-1  px-2"><img src="../assets/instagram.png" class="w-[18px] h-[18px] " alt=""></a>
                      <a href="https://www.youtube.com/@Sehamx" class="text-slate-300 pl-1 px-2"><img src="../assets/youtube.png" class="w-[18px] h-[18px] " alt=""></a>
                      <a href="https://discord.com/invite/Sehamx" class="text-slate-300 pl-1 px-2"><img src="../assets/discord.png" class="w-[18px] h-[18px] " alt=""></a>
                      <a href="#tiktok" class="text-slate-300 pl-1 px-2"><img src="../assets/tiktok.png" class="w-[18px] h-[18px]" alt=""></a>
                      <a href="https://kick.com/Sehamx" class="text-slate-300 pl-1 px-2"><img src="../assets/kick.png" class="w-[18px] h-[18px] " alt=""></a>
                    </li>
                </ul>
            </div>
        </div>

        <div v-else-if="index === 2" class="img-wrapper relative font-rubik flex flex-wrap">
            <div class="container flex flex-col justify-center items-center my-10">
              <div class="levelup-rewards flex flex-row justify-center items-center mb-3">
                <img class="h-[42px] w-[30px] max-w-full" src="../assets/gold-medal.png" alt="gold medal">
                <h2 class="uppercase font-bold xsm:text-[20px] sm:text-[25px] drop-shadow text-white">LEVEL UP REWARDS</h2>
              </div>
              <img class="md:w-[590px] md:h-[340px] xsm:w-full  max-w-full" src="../assets/Diamonds.png" alt="diamond">
            </div>
        </div>

        <div v-else-if="index === 3" class="img-wrapper relative font-rubik flex md:flex-row flex-wrap justify-center xl:flex-row 2xl:flex-row lg:flex-row items-center">
            <div class="img-container flex-1 pt-10 sm:block xsm:hidden">
                <img :src="image.slideFour" class=" m-auto md:h-[373px] md:w-[380px] sm:h-[300px] xsm:w-full xsm:h-[369px] drop-shadow rounded" alt="">
            </div>
            <div class="slide-contents flex-1 xsm:mx-5 sm:text-left xsm:text-center">
                <ul>
                    <li class="flex items-center xsm:justify-center">
                      <img src="../assets/discord.png" class="w-[16px] h-[16px]" alt="">
                      <a href="https://discord.com/invite/Sehamx" class="text-slate-300 pl-1">Join Our Discord</a>
                    </li>
                    <li class=""><h2 class="uppercase font-extrabold drop-shadow text-[#08E59A] text-[45px] xsm:text-[38px]">CHECK OUR REWARDS SYSTEM</h2></li>
                    <li><h2 class="uppercase font-medium drop-shadow text-slate-200 text-[20px] xsm:text-[18px]">See How You Can Unlock Exclusive Perks!</h2></li>
                    <li><p class="font-medium text-slate-400 text-[16px] xsm:text-[14px] py-2">Your complete guide to earning rewards faster and easier!</p></li>
                    <li><p class="font-medium text-slate-400 text-[16px] xsm:text-[14px]">Follow our guide to make the most of your membership</p></li>
                    <li class="py-5">
                        <router-link to="rewards-and-promotions/our-rewards-system" class="inline-flex items-center w-full px-5 py-3 mb-3 mr-1 text-base font-semibold 
                                        text-white no-underline align-middle bg-[#000]/60 border border-transparent border-solid rounded-md 
                                        cursor-pointer select-none sm:mb-0 sm:w-auto hover:bg-[#08E59A] hover:[#08E59A] hover:text-white 
                                        focus-within:bg-[#08E59A] focus-within:border-[#08E59A]">See Full Details
                          <svg class="w-4 h-4 ml-2" 
                                fill="#fff" 
                                stroke="#fff" 
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" 
                                stroke-linejoin="round" stroke-width="2" 
                                d="M14 5l7 7m0 0l-7 7m7-7H3">
                            </path>
                          </svg>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </template>
  
  <script>
    export default {
        data () {
        return {
            color: {
              primary: '#08573D',
            },
            slides: [
            'First',
            'Second',
            'Third',
            'Fourth',
            ],
            image: {
                    slideOne: require('../assets/first-slide-bg.png'),
                    slideTwo: require('../assets/seham-logo.png'),
                    slideFour: require('../assets/ai-king.png')
                }
        }
        },
        methods: {
          isMobile(){
            return window.innerWidth < 640;
          }
        },
    }
</script>

<style scoped>
.font-rubik {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
}

.sheet-color-none {
  background-color: rgba(255, 255, 255, 0);
}
.v-carousel :deep(.v-btn.v-btn--icon) {
  background-color: rgba(0, 0, 0, 0.7) !important;
  color: white !important;
}

.v-carousel :deep(.v-btn.v-btn--icon:hover) {
  background-color: rgba(0, 0, 0, 0.9) !important;
}

</style>
