<template>
  <!-- Main section start -->
  <section class="h-auto main-bg" id="main">
    <div
      class="z-30 flex flex-col p-8 pt-20 pb-10 md:pt-40 md:pb-20 lg:pb-20 lg:pt-28 2xl:pt-44 lg:px-16 2xl:px-56"
    >
      <div class="flex justify-center mt-16">
        <img
          v-motion-pop-visible
          src="../assets/title-text.png"
          class=""
          alt="title-text"
        />
      </div>
      <div
        v-motion-pop-visible
        class="flex flex-col items-center justify-center gap-2 mt-10 lg:mt-14"
      >
        <div class="text-3xl font-bold text-white uppercase">
          Catch me live on
        </div>
        <div class="text-[#0FAF79] uppercase text-2xl font-bold">Kick</div>
        <div>
          <a href="https://kick.com/Sehamx" target="_blank">
            <img
              src="../assets/kick-code.png"
              class="transition duration-200 ease-in-out hover:scale-110"
              alt="kick-code"
            />
          </a>
        </div>
      </div>
      <div
        class="flex flex-col items-center justify-center gap-10 md:flex-row md:gap-10 lg:gap-32 mt-14 md:mt-24 lg:mt-40 2xl:mt-52"
      >
        <div>
          <div class="z-0 hidden md:block">
            <div class="smoke-wrap ml-[50px]">
              <img class="smoke" src="../assets/smoke.png" alt="smoke" />
            </div>
            <div class="smoke-wrap ml-[50px]">
              <img class="smoke2" src="../assets/smoke.png" alt="smoke" />
            </div>
            <div class="smoke-wrap ml-[50px]">
              <img class="smoke3" src="../assets/smoke.png" alt="smoke" />
            </div>
          </div>
          <a href="https://stake.com/?offer=seham&c=cOV6GvSE" target="_blank">
            <img
              src="../assets/stake-com-btn.png"
              class="z-10 transition duration-200 ease-in-out hover:scale-110"
              alt="stake-com"
            />
          </a>
        </div>
        <div>
          <div class="z-0 hidden md:block">
            <div class="smoke-wrap ml-[50px]">
              <img class="smoke" src="../assets/smoke.png" alt="smoke" />
            </div>
            <div class="smoke-wrap ml-[50px]">
              <img class="smoke2" src="../assets/smoke.png" alt="smoke" />
            </div>
            <div class="smoke-wrap ml-[50px]">
              <img class="smoke3" src="../assets/smoke.png" alt="smoke" />
            </div>
          </div>
          <a href="https://stake.us/?offer=seham&c=cOV6GvSE" target="_blank">
            <img
              src="../assets/stake-us-btn.png"
              class="transition duration-200 ease-in-out hover:scale-110"
              alt="stake-com"
            />
          </a>
        </div>
      </div>
    </div>
  </section>
  <section class="h-[310px] signup-bg">
    <div
      class="px-6 py-16 text-4xl font-bold text-center text-white lg:text-left md:text-5xl md:py-28 md:ml-6 lg:py-28 lg:ml-32"
    >
      SIGN UP AND GET EXCITING
      <br />REWARDS EVERYDAY!
    </div>
  </section>

  <!-- Stream Section -->
  <section
    class="h-auto cards-bg px-6 md:px-6 lg:px-[120px] 2xl:px-[340px] pt-14 pb-14 md:pt-24 md:pb-20 lg:pt-24 lg:pb-24"
  >
    <div class="flex flex-col items-center justify-center">
      <p
        v-motion-pop-visible
        class="bg-gradient-to-r from-[#24FF00] to-[#00E0FF] text-transparent bg-clip-text font-bold text-[32px] uppercase tracking-widest"
      >
        watch
      </p>
      <p
        v-motion-pop-visible
        class="text-[30px] md:text-[40px] font-bold text-white uppercase text-center"
      >
        latest stream
      </p>
    </div>
    <div
      class="flex flex-col lg:flex-row justify-center items-center mt-14 max-w-[1200px] mx-auto"
    >
      <div class="flex items-center justify-center w-full">
        <iframe
          v-motion-slide-visible-left
          src="https://player.kick.com/Sehamx"
          frameborder="0"
          scrolling="no"
          allowfullscreen="true"
          class="rounded-xl sm:h-[338px] xsm:h-[180px] xsm:w-[310px] sm:w-[600px] md:h-[410px] md:w-[740px] lg:h-[300px] lg:w-[525px] xl:h-[510px] xl:w-full"
        >
        </iframe>
      </div>
      <div
        v-motion-pop-visible
        class="flex flex-col justify-center items-center px-4 md:w-[60%] gap-4 z-20 relative mt-10 xl:mt-0"
      >
        <div class="text-center w-[70%]">
          <img src="../assets/seham-logo.png" alt="stream" />
        </div>
        <p class="text-sm text-center text-white uppercase font-base md:px-14">
          Live Daily (3PM GMT)<br />
        </p>
        <a
          href="https://kick.com/Sehamx"
          target="_blank"
          class="relative px-16 py-4 text-base font-bold text-center uppercase"
          v-motion-pop-visible
        >
          <img src="../assets/visit-stream-btn.png" alt="stream" />
        </a>
      </div>
    </div>
  </section>

  <section class="h-[300px] px-4 py-8 md:px-4 md:py-14 lg:py-14">
    <div
      class="text-4xl font-bold text-center text-white uppercase md:text-5xl"
    >
      VISIT STAKE.COM or stake.us
      <br />AND USE
      <span
        class="bg-gradient-to-r from-[#24FF00] to-[#00E0FF] text-transparent bg-clip-text"
        >CODE: SEHAM</span
      >
    </div>
    <!--<div class="mt-10 text-xl font-bold text-center text-white uppercase md:text-3xl">
        join the fun battles and openings with <span class="bg-gradient-to-r from-[#24FF00] to-[#00E0FF] text-transparent bg-clip-text">code:seham!</span>
      </div>-->
  </section>
  <!-- <section class="h-auto home-bg">
    <BonusOfferSection/>
    
  </section> -->
  <LeaderboardPage />
  <SocialSection />
  <ScheduleSection />
</template>

<script>
import ScheduleSection from "../sections/ScheduleSection.vue";
import SocialSection from "../sections/SocialSection.vue";
import LeaderboardPage from "./LeaderboardPage.vue";

import { defineComponent } from "vue";

export default defineComponent({
  components: {
    SocialSection,
    LeaderboardPage,
    ScheduleSection,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {},
});
</script>

<style scope>
body {
  background-color: black;
}
.main-bg {
  background-image: url("../assets/hero-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}
.cards-bg {
  background-image: url("../assets/cards-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.signup-bg {
  background-image: url("../assets/signup-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.smoke-wrap-2 {
  position: absolute;
  transform: translateY(-30%);
}

.smoke-wrap {
  position: absolute;
  transform: translateY(-80%);
}

.smoke,
.smoke2,
.smoke3 {
  filter: blur(5px);
  transform-origin: 50% 50%;
}

.smoke {
  animation: smoke1 3s linear infinite;
  animation-delay: 0.5s;
}

.smoke2 {
  animation: smoke2 3s linear infinite;
  animation-delay: 1.5s;
}

.smoke3 {
  width: 180px;
  animation: smoke3 4s linear infinite;
  animation-delay: 2.5s;
}

@keyframes smoke1 {
  0% {
    filter: blur(0px);
    transform: translateY(0px) scale(-1, 1);
    opacity: 0;
  }

  25% {
    filter: blur(3px);
    transform: translateY(-10px) scale(-1, 1.05);
    opacity: 0.5;
  }

  50% {
    filter: blur(5px);
    transform: translateY(-20px) scale(-1, 1.1);
    opacity: 1;
  }

  75% {
    filter: blur(5px);
    transform: translateY(-30px) scale(-1, 1.15);
    opacity: 0.5;
  }

  100% {
    filter: blur(7px);
    transform: translateY(-40px) scale(-1, 1.2);
    opacity: 0;
  }
}

@keyframes smoke2 {
  0% {
    filter: blur(0px);
    transform: translateY(0px) scale(1);
    opacity: 0;
  }

  25% {
    filter: blur(3px);
    transform: translateY(-10px) scale(1.05);
    opacity: 0.5;
  }

  50% {
    filter: blur(5px);
    transform: translateY(-20px) scale(1.1);
    opacity: 1;
  }

  75% {
    filter: blur(5px);
    transform: translateY(-30px) scale(1.15);
    opacity: 0.5;
  }

  100% {
    filter: blur(7px);
    transform: translateY(-40px) scale(1.2);
    opacity: 0;
  }
}

@keyframes smoke3 {
  0% {
    filter: blur(0px);
    transform: translateY(0px) scale(1);
    opacity: 0;
  }

  25% {
    filter: blur(3px);
    transform: translateY(-20px) scale(1.05);
    opacity: 0.5;
  }

  50% {
    filter: blur(5px);
    transform: translateY(-40px) scale(1.1);
    opacity: 1;
  }

  75% {
    filter: blur(5px);
    transform: translateY(-60px) scale(1.15);
    opacity: 0.5;
  }

  100% {
    filter: blur(7px);
    transform: translateY(-80px) scale(1.2);
    opacity: 0;
  }
}
</style>
