<template>
  <!-- Footer start -->
  <div class="bg-[#171616] h-full w-full px-8 py-8 md:px-10 md:py-10 lg:px-28 2xl:px-44 lg:py-14">
    <div class="flex flex-col md:flex-row justify-start items-start gap-8 md:gap-24" style="font-family: Open Sans, sans-serif;">
        <div class="flex flex-col justify-start gap-6 w-full md:w-[50%]">
          <div class="text-white text-2xl">
            We urge you to seek <span class="text-[#029B38]">help</span> and <span class="text-[#029B38]">support</span>
          </div>
          <div class="text-white text-sm">
            If you ever feel that gambling is becoming a problem. Most people gamble for fun and enjoyment. However, some people think of gambling as a way to make money, spend more than they can afford, or use gambling to distract themselves from everyday problems.
          </div>
          <div class="text-white text-sm">
            To find further advice, help and support visit: 
            <br><a href="https://www.begambleaware.org" target="_blank" rel="noopener noreferrer">https://www.begambleaware.org/</a>
          </div>
        </div>
        <div class="flex flex-col justify-start gap-4 w-[50%]">
          <div class="text-[#029B38] text-xl">
            Links
          </div>
          <a href="/#socials" class="text-white text-xs">
            Socials
          </a>
          <a href="/#leaderboard" class="text-white text-xs">
            Leaderboard
          </a>
          <a href="/#schedule" class="text-white text-xs">
            Schedules
          </a>
          <a href="/store" class="text-white text-xs">
            Store
          </a>
        </div>
    </div>
    <div class="flex flex-col justify-center items-center gap-6 mt-8 md:mt-10 lg:mt-14">
        <div class="text-center text-white text-xs">
          We do not take responsibility for misunderstood promotions, losses from gambling in casinos or betting on sites which are linked to or promoted on this website. Make sure it is legal for you to use each site inside your jurisdiction and ALWAYS gamble responsibly!
        </div>
        <div class="text-center text-white text-[10px]">
          2024 Copyright: Seham. All Rights Reserved 
        </div>
    </div>
  </div>
  
    
  <!-- Footer end -->
</template>

<script>
export default {

};
</script>

<style scoped>

</style>
