<template>
    <section class="h-full page-bg relative">
        <div class="flex flex-col items-center justify-center lg:pt-32 md:pt-20 xsm:pt-20 font-russo" >
            <div class="container carousel-wrapper">
                <div class="flex justify-start max-w-[1121px] w-full m-auto">
                    <h2 class="h2 text-white text-lg font-normal pb-2 xsm:px-1 2xl:mx-30 max-w-[1121px] flex 2xl:justify-center">Rewards and Promotions</h2>
                </div>
                    <div class="flex flex-col justify-center items-center">
                        <PromotionCarousel></PromotionCarousel>
                    </div>
            </div>
        </div>
        <div class="related-guides-wrapper lg:h-[60vh] md:h-[80vh] sm:h-[60vh] xsm:[25vh] flex flex-nowrap lg:justify-start lg:mx-[15.5%] xsm:px-6">
            <ul class="">
                <li class="text-white text-lg font-normal uppercase font-russo xsm:px-1">Related Guides</li>
                <div class="container flex sm:flex-row font-rubik xsm:flex-col">
                    <router-link to="rewards-and-promotions/how-to-create-account-on-stake"  class="text-white md:w-[280px] md:h-[286px] sm:h-[270px] xsm:w-[268px] py-3 mr-5">
                        <li class="">
                            <img class="w-full h-[160px] rounded-t" src="../assets/person-with-cards.jpg" alt="">
                            <div class="guide-label text-left bg-[#000] px-2 py-3 rounded-b flex flex-wrap">
                                <p class="text-white font-bold md:text-lg sm:text-normal pb-1">How To Create Account on Stake</p>
                                <p class="text-slate-300 text-normal">Learn how to sign up quickly and start 
                                    enjoying exclusive rewards!</p>
                                    <a href="#_" class="inline-flex items-center w-full py-3 text-base font-semibold text-slate-300 no-underline align-middle 
                                                        border border-transparent border-solid rounded-md cursor-pointer select-none sm:mb-0 sm:w-auto 
                                                        hover:text-white focus-within:bg-[#000] focus-within:border-[#000]">Learn More
                                        <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" 
                                                stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3">
                                            </path>
                                        </svg>
                                    </a>
                            </div>
                        </li>
                    </router-link>
                    <router-link to="rewards-and-promotions/our-rewards-system"  class="text-white md:w-[300px] sm:h-[286px] xsm:w-[285px] py-3">
                        <li class="">
                            <img class="w-full h-[160px] rounded-t" src="../assets/reward-sys.jpg" alt="">
                            <div class="guide-label text-left bg-[#000] px-2 py-3 rounded-b flex flex-wrap">
                                <p class="text-white font-bold text-lg pb-1">Our Rewards System</p>
                                <p class="text-slate-300 text-normal">Discover the benefits of being a member 
                                    and start maximizing your rewards!</p>
                                    <a href="#_" class="inline-flex items-center w-full py-3 text-base font-semibold text-slate-300 no-underline align-middle 
                                                        border border-transparent border-solid rounded-md cursor-pointer select-none sm:mb-0 sm:w-auto 
                                                        hover:text-white focus-within:bg-[#000] focus-within:border-[#000]">Learn More
                                        <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path stroke-linecap="round" stroke-linejoin="round" 
                                                stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3">
                                            </path>
                                        </svg>
                                    </a>
                            </div>
                        </li>
                    </router-link>
                </div>
                
            </ul>
        </div>
    </section>
    <router-view></router-view>
</template>

<script>
import PromotionCarousel from '../components/PromotionCarousel.vue';
    
    export default {
        components: {
            PromotionCarousel,
        }
        
    }
</script>

<style scoped>
.page-bg {
    background-image: url('../assets/page-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
}
.v-carousel__controls {
    bottom: -40px !important;
}
.font-rubik {
    font-family: "Rubik", sans-serif;
    font-optical-sizing: auto;
}
.font-russo {
    font-family: Russo One, sans-serif;
    font-optical-sizing: auto;

}

</style>