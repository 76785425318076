<template>
	<section class="h-screen page-bg px-5 md:px-5 lg:px-8">
		<div
			class="flex flex-col justify-start items-start py-44 md:pt-44"
			style="font-family: Russo One, sans-serif"
		>
			<!-- Profile page -->
			<div class="text-left flex mb-10 ml-4">
				<h1 class="uppercase text-[#0fb97f] text-xl md:text-4xl">
					Profile page
				</h1>
			</div>
			<!-- Profile content -->
			<div class="flex m-4 p-4 border-8 border-[#029D65] w-full">
				<div
					class="profile-bg rounded-lg grow w-full h-auto lg:h-[680px] shadow-xl"
				>
					<div class="flex flex-row">
						<div class="basis-1/3"></div>
						<div
							class="basis-1/3 flex flex-row items-center justify-center pt-16 pb-16"
						>
							<img
								src="../assets/profile-logo.svg"
								alt="profile"
								class="w-44 h-44 rounded-full"
								v-if="!discordAvatar"
							/>
							<img
								:src="discordAvatar"
								alt="profile"
								class="w-44 h-44 rounded-full"
								v-else
							/>
							<span class="text-white ml-3 mt-4 text-5xl">
								{{ discordName ?? "Player #123456" }}
							</span>
						</div>
						<div class="basis-1/3 text-center pt-4">
							<button
								class="bg-[#0fb97f] px-10 py-5 text-xl text-black uppercase"
								@click="onEdit()"
							>
								{{ buttonType }}
							</button>
						</div>
					</div>
					<div class="flex flex-col lg:flex-row">
						<!-- Acoounts -->
						<div class="uppercase text-center grow">
							<div class="flex flex-col">
								<h1 class="text-white text-5xl mb-8">Accounts</h1>
								<p class="uppercase text-gray-400 text-xl ml-2 mb-2">
									Discord ID: {{ discordId }}
								</p>
								<p class="uppercase text-gray-400 text-xl ml-2 mb-2">
									Kick username: kick101
								</p>
								<p class="uppercase text-gray-400 text-xl ml-2 mb-2">
									Stake username: stake101
								</p>
							</div>
						</div>
						<!-- Socials -->
						<div class="uppercase text-center grow">
							<div class="flex flex-col">
								<h1 class="text-white text-5xl text-center mb-8">Socials</h1>
								<ul>
									<li>
										<p class="uppercase text-white text-xl ml-2 mb-2">
											Twitter:
										</p>
									</li>
									<li>
										<p class="uppercase text-white text-xl ml-2 mb-2">
											Instagram:
										</p>
									</li>
									<li>
										<p class="uppercase text-white text-xl ml-2 mb-2">
											Youtube:
										</p>
									</li>
									<li>
										<p class="uppercase text-white text-xl ml-2 mb-2">
											Tiktok:
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="flex flex-col lg:flex-row">
						<div class="pt-12 px-8">
							<p class="text-[#24FF00] font-thin">Disclaimer:</p>
							<ul class="uppercase">
								<li>
									<p class="text-[#24FF00] font-thin">
										Users must put the correct information onset during stake
										username registration through discord to avoid discrepancies
										in points or else points won’t be distributed correctly.
									</p>
								</li>
								<li>
									<p class="text-[#24FF00] font-thin">
										any loss of points, the seham team will not be held liable
										due to incorrect information entered on discord (this is
										also a way to prevent alt accounts farming for points) so
										please be careful in entering the right information.
									</p>
								</li>
								<li>
									<p class="text-[#24FF00] font-thin">
										for any changes, please go through discord and make a ticket
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!-- Purchase transactions -->
			<div class="text-left flex mt-12 mx-24 flex-col">
				<h1 class="uppercase text-[#0fb97f] mb-8 text-xl md:text-4xl">
					Purchase transactions
				</h1>
				<ul class="uppercase text-xl">
					<li class="text-white mb-1">
						You’ve won guess the payout on 09/22/2024! please go to discord,
						make a ticket and claim your prize!
					</li>
					<li class="text-white mb-1">
						You’ve won guess the payout on 09/22/2024! please go to discord,
						make a ticket and claim your prize!
					</li>
					<li class="text-white mb-1">
						You’ve won guess the payout on 09/22/2024! please go to discord,
						make a ticket and claim your prize!
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
import { getUser } from "../api/users.ts";
import { mapGetters, mapMutations } from "vuex";

export default {
	data() {
		return {
			buttonType: "Edit",
			isEdit: false,
			discordId: null,
            discordName: null,
			discordAvatar: null,
		};
	},
	computed: {
		...mapGetters(["getItem"]),
	},
	methods: {
		...mapMutations(["UPDATE_USER"]),

		onEdit() {
			this.isEdit = !this.isEdit;
			this.buttonType = this.isEdit ? "Save" : "Edit";
		},

		async fetchData() {
			const token = localStorage.getItem("seham-access-token");
			if (!token) {
				localStorage.removeItem("vuex");
				localStorage.removeItem("seham-access-token");
				this.$router.push("/login");
			}
			// Get discord data
			this.discord = await getUser();

			// Save discord data to vuex
			if (this.discord !== null) {
				this.UPDATE_USER(this.discord.user);
			}

            // Set discord data
			this.discordName = this.discord.user.displayName;
            this.discordId = this.discord.user.discordId;
			this.discordAvatar =
				"https://cdn.discordapp.com/avatars/" +
				this.discord.user.discordId +
				"/" +
				this.discord.user.profileImage +
				".png";
		},
	},
	mounted() {
		this.fetchData();
	},
};
</script>

<style scoped>
.page-bg {
	background-image: url("../assets/page-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;
}

.profile-bg {
	background-image: linear-gradient(
			to bottom right,
			rgba(87, 85, 85, 0.5),
			rgba(2, 155, 56, 0.5),
			rgba(8, 87, 61, 0.5)
		),
		url("../assets/profilepage-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
</style>
